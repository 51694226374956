import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  ServiceReportFindOptions,
  GeneratedServiceReportFindResponse,
  ServiceSummaryDto,
  ServiceVehiclesSummaryQuarterlyDto,
} from '../models/service-report'
import { setHttpParams } from './helpers/set-http-params.helper'
import { map, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class GeneratedServiceReportApi {
  constructor(private http: HttpClient) {}

  public find(params: ServiceReportFindOptions) {
    return this.http.get<GeneratedServiceReportFindResponse>(
      `/GeneratedServiceReport`,
      {
        params: setHttpParams(params),
      }
    )
  }

  public delete(id: string) {
    return this.http.delete<any>(`/GeneratedServiceReport/${id}`)
  }

  public getDownloadUrl(id: string) {
    return this.http.get<any>(`/GeneratedServiceReport/getDownloadUrl/${id}`)
  }

  public getVehiclesSummaryWeekly(dto: ServiceSummaryDto): Observable<{
    buffer: ArrayBuffer
    csvName: string
  }> {
    return this.http
      .post(`/GeneratedServiceReport/serviceVehiclesSummaryWeekly`, dto, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => ({
          buffer: response.body,
          csvName: getFilenameFromContentDisposition(
            response.headers.get('content-disposition')
          ),
        }))
      )
  }

  public getVehiclesSummaryMonthly(dto: ServiceSummaryDto): Observable<{
    buffer: ArrayBuffer
    csvName: string
  }> {
    return this.http
      .post(`/GeneratedServiceReport/serviceVehiclesSummaryMonthly`, dto, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => ({
          buffer: response.body,
          csvName: getFilenameFromContentDisposition(
            response.headers.get('content-disposition')
          ),
        }))
      )
  }

  public getVehiclesSummaryQuarterly(
    dto: ServiceVehiclesSummaryQuarterlyDto
  ): Observable<{
    buffer: ArrayBuffer
    csvName: string
  }> {
    return this.http
      .post(`/GeneratedServiceReport/serviceVehiclesSummaryQuarterly`, dto, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => ({
          buffer: response.body,
          csvName: getFilenameFromContentDisposition(
            response.headers.get('content-disposition')
          ),
        }))
      )
  }

  public getGallonsAndPriceWeekly(dto: ServiceSummaryDto): Observable<{
    buffer: ArrayBuffer
    csvName: string
  }> {
    return this.http
      .post(`/GeneratedServiceReport/serviceGallonsAndPriceWeekly`, dto, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => ({
          buffer: response.body,
          csvName: getFilenameFromContentDisposition(
            response.headers.get('content-disposition')
          ),
        }))
      )
  }

  public getGallonsAndPriceMonthly(dto: ServiceSummaryDto): Observable<{
    buffer: ArrayBuffer
    csvName: string
  }> {
    return this.http
      .post(`/GeneratedServiceReport/serviceGallonsAndPriceMonthly`, dto, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response) => ({
          buffer: response.body,
          csvName: getFilenameFromContentDisposition(
            response.headers.get('content-disposition')
          ),
        }))
      )
  }
}

function getFilenameFromContentDisposition(header: string | null): string {
  return header?.split('filename=')[1]?.split(';')[0] ?? ''
}
